import React, { useEffect, useState } from "react";
import "./pizzaButton.scss";

const PizzaButton = () => {

   const [pizzaMenuData, setPizzaMenuData] = useState<any[]>([]);
   const [open, setOpen] = useState(false);

   useEffect(() => {
      const _get = fetch("/components/chatbot-info.json");
      _get.then(async (resp) => {
         const chatbotInfo = await resp.json();
         setPizzaMenuData(chatbotInfo);
      });
   }, []);

   const renderIcon = () => {
      if (pizzaMenuData[0].iconoCerrar) {
         return pizzaMenuData[0].iconoCerrar.file.url;
      }
      return "X";
   };

   const handleClick = (e, link) => {
      e.preventDefault();
      if (link) {
         window.open(link, "mywindow");
      }
   };

   return (
      <div className="m-circularMenu">
         {pizzaMenuData.length > 0 && (
            <>
               <div
                  onClick={() => setOpen(false)}
                  className={`m-circularMenu__background ${
                     open ? `--active` : ""
                  }`}
               ></div>
               <button
                  onClick={() => setOpen(!open)}
                  tabIndex={1}
                  aria-label="Menú de atención al usuario"
                  className={`m-circularMenu__btn ${open ? `--opened` : ""}`}
               >
                  {open && <img src={renderIcon()} alt="Cerrar Menú" />}
               </button>
               <div
                  className={`m-circularMenu__buttons ${
                     open ? `--active` : ""
                  }`}
               >
                  {pizzaMenuData[0].botonesInternos.map((btn: any, index: number) => (
                     <button
                        className="m-circularMenu__buttons__btn"
                        key={btn.nombre}
                        onClick={(e) => handleClick(e, btn.link)}
                        tabIndex={index + 2}
                     >
                        <div className="m-circularMenu__buttons__btn__name">
                           <span>{btn.nombre}</span>
                        </div>
                        <div className="m-circularMenu__buttons__btn__icon">
                           {btn.icono ? (
                              <i className={`icon-${btn.icono}`}></i>
                           ) : btn.iconoImagen ? (
                              <img
                                 src={
                                    btn.iconoImagen && btn.iconoImagen.file.url
                                 }
                                 alt={btn.nombre}
                              />
                           ) : null}
                        </div>
                     </button>
                  ))}
               </div>
            </>
         )}
      </div>
   );
};

export default PizzaButton;
