import React, { useState, useEffect, useMemo } from 'react'
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import useScrollDetect from '../../../shared/hooks/useScrollDetect'
import useDirectionScroll from '../../../shared/hooks/useDirectionScroll'
import ShareModal from '../../molecules/share-modal/shareModal'
import { getFormatDate } from '../../../shared/utils/dateUtilities'
import Breadcrumb from '../../atoms/breadcrumb/breadcrumb'
import ButtonAuth from '../../molecules/button-auth/buttonAuth'
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"
import getGtmId from '../../../utils/getGtmId'
import Img from "gatsby-image"
import './headboardTabs.scss'

const HeadboardTabs = (props:ReferenciaCabecera) => {

   const { isBrowser, window, document } = useBrowserMode();
   const [tabSelect, setTabSelect] = useState(-1);
   const [upScroll, setUpScroll] = useState(false);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { isScrollActive, refElement } = useScrollDetect();
   const { lastDirection } = useDirectionScroll();

   const arrayBreadCrumb = props.migaDePan.split("/");
   const gtmCategory =
      arrayBreadCrumb && arrayBreadCrumb.length > 0 ? arrayBreadCrumb[2] : "";

   const refTags = (props.referenciaTags || []).map( rt => rt.localizador );

   const goToContent = (index:number ) => {
      if ( isBrowser && refTags.length ) {
         const element = document.getElementById(props.tipoCabecera+refTags[index][0]);
         setUpScroll(true);
         element?.scrollIntoView(true);
      }
   }

   let timerScroll:any;

   const verifyTabs = (index:number) => {
      const indexString = `${index}`
      for(let i = 0; i < refTags.length; i++ ) {
         const indexSearch = refTags[i].find( rt => rt === indexString );
         if( indexSearch !== undefined) {
            setTabSelect(i);
            return;
         }
      }
      setTabSelect(-1);
   } 

   const onScrollAction = () => {
      window.clearTimeout( timerScroll );
      timerScroll = setTimeout(() => {
         if( upScroll ) {
            window.scroll(0, window.pageYOffset - ( isScrollActive ? 138 : 220 ) );
            setUpScroll(false);
         }
         const tabs = document.querySelectorAll('.o-headboard-tabs__tabs__tab');
         const items = document.querySelectorAll(`.${props.tipoCabecera}`);
         for( let i = 0; i < items.length; i++ ) {
            const bottom = items[i].getBoundingClientRect().bottom;
            const top = items[i].getBoundingClientRect().top;
            if( bottom > 139 && top <= 139 ) {
               //setTabSelect(i);
               verifyTabs(i);
               tabs.item(i)?.scrollIntoView(true);
               return;
            }
         } 
         setTabSelect(-1);
      }, 66);
   }

   const doCssChatBot = () => {
      const chatBot = document.body.querySelector('.RecastAppExpander.CaiAppExpander.open');
      chatBot?.classList.add( 'open-service' );
   }

   useEffect( doCssChatBot, [] );

   useEffect(() => {
      isBrowser && document.addEventListener('scroll', onScrollAction);
      return () => {
         isBrowser && document.removeEventListener('scroll', onScrollAction);
      }
   }, [upScroll] );

   useMemo(() => {
      props.superFooter?.set(isScrollActive);
   }, [isScrollActive])

   const toggleModal = () => {
      setIsModalVisible(!isModalVisible);
   }

   const isTabsNoShare = !props.tituloBoton && !props.botonCompartir;
   return (
      <div className={`o-headboard-tabs${props.eventoEspecial ? ' o-headboard-tabs--special-event' : ''}`} ref={refElement} >
      
         <Breadcrumb content = { props.migaDePan } specialEvent = { props.eventoEspecial } />
         <div className={`o-headboard-tabs__container${isTabsNoShare ? ' o-headboard-tabs__container--no-share' : ''}${props.eventoEspecial ? ' o-headboard-tabs__container--special-event' : ''}`} >
            <section className={`o-headboard-tabs__container__content${props.eventoEspecial ? ' o-headboard-tabs__container__content--special-event' : ''}`} >
               <span className={`o-headboard-tabs__container__content__title-span${props.eventoEspecial ? ' o-headboard-tabs__container__content__title-span--special-event' : ''}`} >{props.tituloSpan}</span>
               <h1 className={`o-headboard-tabs__container__content__title${isTabsNoShare ? ' o-headboard-tabs__container__content__title--no-share' : ''}${props.eventoEspecial ? ' o-headboard-tabs__container__content__title--special-event' : ''}`} >{props.tituloPrincipal}</h1>
               { props.verFechaPublicacion &&
                  <span className='o-headboard-tabs__container__content__date' >{getFormatDate(props.updatedAt, 0)}</span>
               }
               { props.descripcion &&
                  <article className={`o-headboard-tabs__container__content__text${props.eventoEspecial ? ' o-headboard-tabs__container__content__text--special-event' : ''}`} >{props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}</article>
               }
               {  props.eventoEspecial &&
                  <div className= 'o-headboard-tabs__container__buttons'>
                     <ButtonAuth 
                        className= 'o-headboard-tabs__container__buttons__button-one'
                        validateAuth= { props.validarBotonAutenticacion }
                        text= { props.tituloBoton }
                        href= { props.redireccionBoton }
                        gtmMark= { `gtm${normalizeTwoWords(props.tituloBoton)}` }
                        strictCss= { true }
                     />
                  </div>
               }
               { props.botonCompartir &&
                  <button 
                     className={`o-headboard-tabs__container__content__share gtm${normalizeTwoWords(
                           gtmCategory
                        )}Articulo${normalizeTwoWords(
                           props.tituloPrincipal
                        )}Compartir`
                     }
                     onClick={toggleModal}
                  >
                     <i className='icon-share' ></i>{props.botonCompartir}
                  </button>
               }
            </section>
            <div className={`o-headboard-tabs__container__cover${isTabsNoShare ? ` o-headboard-tabs__container__cover--hide-m` : ''}${props.eventoEspecial ? '--special-event' : ''}${props.eventoEspecial ? ' o-headboard-tabs__container__cover--special-event' : ''}`} >
               {props.imagenHeader.fluid ?
                  <Img  className="o-headboard-tabs__container__cover__img"
                        fluid= { props.imagenHeader.fluid }
                        alt={ props.imagenHeader.title || "imagen cabecera tabs"}
                  />
                  :
                  <img  className="o-headboard-tabs__container__cover__img"
                     src={props.imagenHeader.file.url}
                     alt={ props.imagenHeader.title || "imagen cabecera tabs"}
                     loading="lazy"
                  />
               }
            </div>
            { props.botonCompartir &&
               <button 
                  className={`o-headboard-tabs__container__share gtm${normalizeTwoWords(
                        gtmCategory
                     )}Articulo${normalizeTwoWords(
                        props.tituloPrincipal
                     )}Compartir`
                  }
                  onClick={toggleModal} 
               >
                  <i className='icon-share' ></i>{props.botonCompartir}
               </button>
            }
         </div>
         { isScrollActive &&
            <div className={`o-headboard-tabs__top${isTabsNoShare ? ' o-headboard-tabs__top--hide-m' : ''}`} >
               <span className='o-headboard-tabs__top__title' >{props.eventoEspecial && `${props.tituloSpan}. `}{props.tituloPrincipal}</span>
               <div className='o-headboard-tabs__top__buttons' >
                  {  props.tituloBoton &&
                     <ButtonAuth
                        id={getGtmId(window.location.pathname)} 
                        className= 'o-headboard-tabs__top__buttons__button-one'
                        validateAuth= { props.validarBotonAutenticacion }
                        text= { props.tituloBoton }
                        href= { props.redireccionBoton }
                        gtmMark= { `gtm${normalizeTwoWords(props.tituloBoton)}` }
                        strictCss= { true }
                     />
                  }
                  { props.botonCompartir &&
                     <button 
                        className={`o-headboard-tabs__top__buttons__button-two icon-share gtm${normalizeTwoWords(
                              gtmCategory
                           )}Articulo${normalizeTwoWords(
                              props.tituloPrincipal
                           )}Compartir`
                        } 
                        onClick={toggleModal}
                     ></button>
                  }
               </div>
            </div>
         }
         { props.referenciaTags &&
            <ul className={`o-headboard-tabs__tabs${props.eventoEspecial ? ' o-headboard-tabs__tabs--special-event' : ''}${isScrollActive ? ` tabs-fixed${props.eventoEspecial ? ' tabs-fixed--special-event' : ''}` : ''}${lastDirection == -1 ? ' tabs-fixed-move':'' }`} >
               {props.referenciaTags.map( (value, index) => (
                  <li
                     key={`headerTab-${index}`}
                     className={`gtmConoceComfamaSuperior${normalizeTwoWords(value.nombreTag)} o-headboard-tabs__tabs__tab${index === tabSelect ? ` tab-active${props.eventoEspecial ? ' tab-active--special-event' : ''}` : ''}`}
                     onClick={ () => goToContent(index) }
                  >{value.nombreTag}</li>
               ))}
            </ul>
         }
         <ShareModal 
            open={isModalVisible}
            onClose={toggleModal}
            gtmClass={`gtm${normalizeTwoWords(
                  gtmCategory
               )}Articulo${normalizeTwoWords(
                  props.tituloPrincipal
               )}Compartir`
            }
         />
      </div>
   )
}

export default HeadboardTabs;
