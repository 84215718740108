import React /* , { useState, useEffect } */ from "react";
import "./buttonBurger.scss";

const ButtonBurger = (props: ButtonBurgerInterface) => {
   const {
      isMenuActive,
      isSearcherActive,
      closeMenu,
      openMenu,
      isNotRoot,
      lastDirection,
      hideHeaderDesktop,
   } = props;

   return (
      <button
         className={
            `a-burger${isMenuActive ? " a-burger-active" : ""}` +
            `${isNotRoot ? " a-burger-no-root" : ""}` +
            `${
               hideHeaderDesktop &&
               !isMenuActive &&
               !isSearcherActive &&
               lastDirection > 0
                  ? " a-burger-no-root-freeze"
                  : ""
            }` +
            " gtmMenuTransMenuDeCategorias"
         }
         type="button"
         onClick={() => {
            isMenuActive ? closeMenu() : openMenu();
         }}
         aria-label="Menú principal"
      >
         <span
            className={`a-burger__line ${
               isMenuActive ? "a-burger__line--active" : ""
            } gtmMenuTransMenuDeCategorias`}
         ></span>
         <span
            className={`a-burger__line ${
               isMenuActive ? "a-burger__line--active" : ""
            } gtmMenuTransMenuDeCategorias`}
         ></span>
         <span
            className={`a-burger__line ${
               isMenuActive ? "a-burger__line--active" : ""
            } gtmMenuTransMenuDeCategorias`}
         ></span>
         <p
            className={`a-burger__txt ${
               isMenuActive ? "a-burger__txt--hide" : ""
            } hide-on-mobile gtmMenuTransMenuDeCategorias`}
         >
            {" "}
            Menú{" "}
         </p>
         <p
            className={`a-burger__txt ${
               isMenuActive ? "" : "a-burger__txt--hide"
            } hide-on-mobile gtmMenuTransMenuDeCategorias`}
         >
            {" "}
            Cerrar{" "}
         </p>
      </button>
   );
};

interface ButtonBurgerInterface {
   isMenuActive: boolean;
   isSearcherActive: boolean;
   isNotRoot?: boolean;
   lastDirection: number;
   hideHeaderDesktop: boolean;
   closeMenu: Function;
   openMenu: Function;
}

export default ButtonBurger;
