import React, { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReferenciaCabecera } from "../../../interfaces/ReferenciaCabecera";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import "./headboardEspecial.scss";

const HeadboardEspecial = (props: ReferenciaCabecera) => {

   const [mobileView, setMobileView] = useState<boolean>(false)
   const { isBrowser, window } = useBrowserMode();

   useEffect(() => {
      isBrowser && window.screen.width < 960 && setMobileView(true)
   }, [])

   if (isBrowser) {
      window.addEventListener("resize", function() {
         isBrowser && window.screen.width < 960 ? setMobileView(true) : setMobileView(false)
      })
   }

   return (
      <div className="o-headboard-especial">
         <div className="o-headboard-especial__info">
            {props.tituloSpan && (
               <span className="o-headboard-especial__info__title-span">
                  {props.tituloSpan}
               </span>
            )}
            {props.tituloPrincipal && (
               <h1 className="o-headboard-especial__info__title">
                  {props.tituloPrincipal}
               </h1>
            )}
            {props.descripcion && (
               <p className="o-headboard-especial__info__text">
                  {documentToReactComponents(props.descripcion.json)}
               </p>
            )}
         </div>
         { !mobileView ? (
            <img
               className="o-headboard-especial__bg"
               src={props.imagenHeader.file.url}
               alt={
                  (props.imagenHeader && props.imagenHeader.title) ||
                  "imagen cabecera"
               }
               loading="lazy"
            />
         ) : (
            <>
               <img
                  className="o-headboard-especial__bg"
                  src={props.imagenesArboles[0].file.url}
                  alt={
                     (props.imagenesArboles[0] &&
                        props.imagenesArboles[0].title) ||
                     "imagen cabecera"
                  }
                  loading="lazy"
               />
               <img
                  className="o-headboard-especial__cover"
                  src={props.imagenesArboles[1].file.url}
                  alt={
                     (props.imagenesArboles[1] &&
                        props.imagenesArboles[1].title) ||
                     "imagen cabecera"
                  }
                  loading="lazy"
               />
            </>
         )}
      </div>
   );
};

export default HeadboardEspecial;
