import React, { useState, useEffect } from 'react';
import Headboard from '../headboard/headboard';
import HeadboardButtons from '../headboard-buttons/headboardButtons';
import HeadboardClaustro from '../headboard-claustro/headboardClaustro';
import HeadboardDientelandia from '../headboard-dientelandia/headboardDientelandia';
import HeadboardEspecial from '../headboard-especial/headboardEspecial';
import HeadboardInforme2022 from '../headboard-informe2022/headboardInforme2022';
import HeadboardNull from '../headboard-null/headboardNull';
import HeadboardSalaPrensa from '../headboard-sala-prensa/headboardSalaPrensa';
import HeadboardSalaPrensa2 from '../headboard-sala-prensa2/headboardSalaPrensa2';
import HeadboardSimple from '../headboard-simple/headboardSimple';
import HeadboardTabs from '../headboard-tabs/headboardTabs';
import HeadboardTrees from '../headboard-trees/headboardTrees';
import headboardMainAdapter from '../headboard-main/adapter/headboardMain.adapter';
import HeadboardMain from '../headboard-main/headboardMain';
import headboardAniversarioAdapter from '../headboard-aniversario/adapter/headboardAniversario.adapter';
import HeadboardAniversario from '../headboard-aniversario/headboardAniversario';
import Nav from '../nav/nav';
import Footer from '../../molecules/footer/footer';
import Header from '../../molecules/header/header';
import PizzaButton from '../../molecules/pizza-button/pizzaButton';
import GlobalContext from '../../../contexts/globalContext';
import useVerifyChat from '../../../hooks/useVerifyChat';
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera';
import {
    isIOS,
    initFirebase,
    receiveNotifications,
} from '../../../services/firebase.js';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import useChangeTheme from '../../../shared/hooks/useChangeTheme';
import useDirectionScroll from '../../../shared/hooks/useDirectionScroll';
import useLoginGigya from '../../../shared/hooks/useLoginGigya';
import './layout.scss';

interface LayoutInterface {
    children: any;
    headboard?: ReferenciaCabecera;
    isNotRoot?: boolean;
    hideHeaderDesktop: boolean;
    hideHeaderMobile: boolean;
    typeChat?: string;
    menuEspecifico?: string;
    esconderLoggin?: boolean;
    hideSidebar?: boolean;
    footerImg?: string;
    glassHeader?: boolean;
    footerWhite?: boolean;
    footerBlack?: boolean;
    hideBurger?: boolean;
}

const Layout = (props: LayoutInterface) => {
    const [isMenuActive, setMenuActive] = useState(false);
    const [isSearcherActive, setSearcherActive] = useState(false);
    const [_scrollActive, setScrollActive] = useState(false);
    const [size, setSize] = useState(0);
    const [_superFooter, setSuperFooter] = useState<boolean>(false);
    const [isDark, changeDarkMode] = useChangeTheme();
    const { lastDirection, resetDirection } = useDirectionScroll();
    const { isBrowser, localStorage, document, window } = useBrowserMode();
    const { verifyChat } = useVerifyChat();
    const gigyaContext = useLoginGigya();

    useEffect(() => {
        props.typeChat && verifyChat(props.typeChat);
    }, []);

    useEffect(() => {
        if (isBrowser) {
            const realSize = parseInt(
                localStorage.getItem('font-size') || '100',
            );
            const item = document.querySelector('html') || {
                style: { fontSize: realSize },
            };
            item.style.fontSize = `${realSize}%`;
            setSize(realSize);
            toLocalStoragepageActive();
        }
        isBrowser && document.addEventListener('scroll', onScrollAction);
        return () => {
            isBrowser && document.removeEventListener('scroll', onScrollAction);
        };
    });

    useEffect(() => {
        if (!sessionStorage.getItem('TokenFCM') && !isIOS) {
            initFirebase();
        }
        if (!isIOS) receiveNotifications();
    }, []);

    const doSetSize = (_size: number) => {
        localStorage.setItem('font-size', `${_size}`);
        setSize(_size);
    };

    const toLocalStoragepageActive = () => {
        if (localStorage.getItem('Redirection')) {
            const storage: any = localStorage.getItem('Redirection');
            const data = JSON.parse(storage);
            if (data.titleButton === 'COMFAMA.COM') {
                return false;
            } else {
                return localStorage.setItem(
                    'Redirection',
                    JSON.stringify({ titleButton: 'COMFAMA.COM', path: '' }),
                );
            }
        } else {
            return localStorage.setItem(
                'Redirection',
                JSON.stringify({ titleButton: 'COMFAMA.COM', path: '' }),
            );
        }
    };

    const scrollActive = { get: _scrollActive, set: setScrollActive };

    const context = {
        fontSize: { get: size, set: doSetSize },
        superFooter: { get: _superFooter, set: setSuperFooter },
        gigyaContext: { ...gigyaContext },
    };

    let timerScroll: any;

    const onScrollAction = () => {
        window.clearTimeout(timerScroll);
        timerScroll = setTimeout(() => {
            if (window.pageYOffset < 70) {
                resetDirection();
            }
        }, 66);
    };

    const switchHeadBoard = (headboard: ReferenciaCabecera, superFooter) => {
        switch (headboard.tipoCabecera) {
            case 'CabeceraPrincipal':
                return <Headboard {...headboard} />;
            case 'CabeceraTabs':
                return (
                    <HeadboardTabs superFooter={superFooter} {...headboard} />
                );
            case 'CabeceraBotones':
                return (
                    <HeadboardButtons
                        superFooter={superFooter}
                        {...headboard}
                    />
                );
            case 'CabeceraSimple':
                return <HeadboardSimple {...headboard} />;
            case 'CabeceraSalaPrensa':
                return <HeadboardSalaPrensa {...headboard} />;
            case 'CabeceraSalaPrensa2':
                return <HeadboardSalaPrensa2 {...headboard} />;
            case 'CabeceraClaustro':
                return <HeadboardClaustro {...headboard} />;
            case 'CabeceraArboles':
                return <HeadboardTrees {...headboard} />;
            case 'CabeceraDientelandia':
                return <HeadboardDientelandia {...headboard} />;
            case 'CabeceraAsamblea2022':
                return <HeadboardInforme2022 {...headboard} />;
            case 'CabeceraEspecial':
                return <HeadboardEspecial {...headboard} />;
            case 'SinCabecera':
                return <HeadboardNull />;
            case 'Cabecera1':
                return <HeadboardMain {...headboardMainAdapter(headboard)} />;
            case 'CabeceraAniversario':
                return <HeadboardAniversario {...headboardAniversarioAdapter(headboard)} />;
            default:
                return <Headboard {...headboard} />;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            let dots = document.querySelectorAll('.slick-dots');
            if (dots) {
                Array.from(dots).forEach((dotset) => {
                    let buttons = dotset.getElementsByTagName('button');
                    Array.from(buttons).forEach((button) => {
                        button.setAttribute('tabIndex', '-1');
                    });
                });
            }
        }, 3000);
    }, []);

    return (
        <GlobalContext.Provider value={context}>
            <Header
                isNotRoot={props.isNotRoot}
                scrollActive={scrollActive}
                isMenuActive={isMenuActive}
                isSearcherActive={isSearcherActive}
                setMenuActive={setMenuActive}
                setSearcherActive={setSearcherActive}
                isDark={isDark}
                actionTheme={changeDarkMode}
                lastDirection={lastDirection}
                hideHeaderDesktop={props.hideHeaderDesktop}
                hideHeaderMobile={props.hideHeaderMobile}
                hideLoggin={props.esconderLoggin}
                hideBurger={props.hideBurger}
                glassHeader={props.glassHeader}
            />
            <Nav
                isMenuActive={isMenuActive}
                isSearcherActive={isSearcherActive}
                isDark={isDark}
                actionTheme={changeDarkMode}
                menuType={props.menuEspecifico}
                hideSidebar={props.hideSidebar}
            />
            <div>
                {props.headboard &&
                    switchHeadBoard(props.headboard, context.superFooter)}
                <main>{props.children}</main>
            </div>
            <Footer
                isSuperFooter={_superFooter}
                footerImg={props.footerImg}
                footerWhite={props.footerWhite}
                footerBlack={props.footerBlack}
                footerDientelandia={
                    props.headboard?.tipoCabecera === 'CabeceraDientelandia'
                        ? true
                        : false
                }
            />
            {!props.typeChat && <PizzaButton />}
        </GlobalContext.Provider>
    );
};

export default Layout;
