import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import NavComponent from "../nav-component/navComponent";

const NavMenu = (props: NavMenuInterface) => {
   const data = useStaticQuery(graphql`
      query MENU_CATEGORIAS {
         allContentfulMenuCategorias(filter: {titulo: {eq: "Menu comfama.com"}}) {
            nodes {
               categorias {
                  nombreCategoria
                  slug
                  hyperLink
                  referenciaSubcategorias {
                     nombreSubcategoria
                     slug
                     link
                     verEnMenu
                     referenciaSubcategorias {
                        nombreSubcategoria
                        slug
                        link
                        verEnMenu
                     }
                  }
               }
               listaDerecha {
                     slug
                     hyperLink
                     nombreCategoria
                  }
               }
         }
         allContentfulFooter(filter: {titulo: {eq: "Footer comfama.com"}}) {
            nodes {
               categorias {
                  nombreCategoria
                  referenciaSubcategorias {
                     nombreSubcategoria
                     link
                     titulo
                  }
               }
            }
         }
      }
   `);

   return (
      <NavComponent 
         isMenuActive={props.isMenuActive}
         isSearcherActive={props.isSearcherActive}
         sideLinks={props.sideLinks}
         isDark={props.isDark}
         actionTheme={props.actionTheme}
         data={data}
      />
   );
};

interface NavMenuInterface {
   isMenuActive: boolean;
   isSearcherActive: boolean;
   sideLinks: any;
   isDark: boolean;
   actionTheme: () => void;
}

export default NavMenu;
