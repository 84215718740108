import React, { useState, useEffect } from "react";
import Modal from "../../atoms/modal/modal";

import useBrowserMode from "../../../shared/hooks/useBrowserMode";

import "./shareModal.scss";

const social = [
  {
    name: "Facebook",
    icon: "icon-facebook1",
    link: `https://www.facebook.com/sharer/sharer.php?u=`,
  },
  {
    name: "Whatsapp",
    icon: "icon-whatsapp",
    link: `https://api.whatsapp.com/send?text=`,
  },
  {
    name: "Twitter",
    icon: "icon-twitter1",
    link: `https://twitter.com/intent/tweet?text=`,
  },
  {
    name: "Linkedin",
    icon: "icon-linkedin1",
    link: `https://www.linkedin.com/shareArticle?mini=true&url=`,
  },
  {
    name: "Correo",
    icon: "icon-mail",
    link: `mailto:?subject=Comfama&body=Este enlace puede ser de tu interés: `,
  },
];

interface ShareModalInterface {
  open: boolean;
  onClose: Function;
  gtmClass?: string;
}

const ShareModal = (props: ShareModalInterface) => {
  const { open, onClose, gtmClass } = props;
  const { isBrowser, window } = useBrowserMode();
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    isBrowser && setCurrentUrl(window.location.href);
  }, []);

  return (
    <Modal open={open} onClose={() => onClose()} className="m-share-modal">
      <div className="m-share-modal__container">
        <div className="m-share-modal__icon">
          <div className="m-share-modal__header">
            <span>Compartir</span>
            <button className="icon-close" onClick={() => onClose()} />
          </div>
          <ul>
            {social.map((val, key) => (
              <li
                key={
                  key
                } /* className={`gtm${normalizeTwoWords(val.titulo)}`} */
              >
                <a
                  href={val.link + currentUrl}
                  className={`${val.icon} ${gtmClass ? gtmClass + val.name : ""}`}
                  target="_blank"
                  rel="noreferrer"
                ></a>
                {isBrowser && window.screen.width >= 960 && (
                  <span>{val.name}</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;