import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReferenciaCabecera } from "../../../interfaces/ReferenciaCabecera";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";

import "./headboardSimple.scss";

const HeadboardSimple = (props: ReferenciaCabecera) => {
   return (
      <div className="o-headboard-simple">
         <Breadcrumb content={props.migaDePan} />

         <div className={`o-headboard-simple__info`}>
            <h2 className="o-headboard-simple__info-title title-secondary">
               {props.tituloPrincipal}
            </h2>
            <span className="o-headboard-simple__info-subtitle">
               {props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
            </span>
         </div>
      </div>
   );
};

export default HeadboardSimple;
