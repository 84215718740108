import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IHeadboardMainProps } from "../headboardMain";

function headboardMainAdapter(data: any): IHeadboardMainProps {
   return {
      title: data.tituloPrincipal,
      subtitle: data.tituloSpan,
      image: data.imagenHeader.file.url,
      bgImage: data.imagenesArboles[0].file.url,
      description: documentToReactComponents(data.descripcion.json),
      btnLabel: data.tituloBoton,
      redirection: data.redireccionBoton ? data.redireccionBoton : "",
   };
}

export default headboardMainAdapter;
