import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Button from '../../atoms/button/button';
import ButtonBurger from '../../atoms/button-burger/buttonBurger';
import FontSize from '../../atoms/font-size/fontSize';
import Login from '../../atoms/login/login';
import GlobalContext from '../../../contexts/globalContext';
import useScrollDetect from '../../../shared/hooks/useScrollDetect';
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import { LoginGigyaInterface } from '../../../shared/interfaces/LoginGigyaInterface';
import outsideClick from '../../../utils/outsideClick';
import './header.scss';

interface HeaderInterface {
   isDark: boolean;
   isMenuActive: boolean;
   isSearcherActive: boolean;
   isNotRoot?: boolean;
   lastDirection: number;
   hideHeaderDesktop: boolean;
   hideHeaderMobile: boolean;
   scrollActive: { get: boolean, set: (_var:boolean) => void };
   setMenuActive: ( isMenuActive: boolean ) => void;
   setSearcherActive: ( isSearcherActive: boolean ) => void;
   actionTheme: () => void;
   hideLoggin?: boolean;
   hideBurger?: boolean;
   glassHeader?: boolean;
}

const Header = ( props: HeaderInterface ) => {

   const data = useStaticQuery(graphql`
   query HEADER_CONTENT {
      allContentfulMenuLoggin(filter: {titulo: {eq: "Menu login comfama.com"}}) {
        nodes {
          inicioSesion
          logoGray {
            title
            file {
              url
              fileName
            }
          }
          logoMagenta {
            title
            file {
              url
              fileName
            }
          }
          logoWithe {
            title
            file {
              url
              fileName
            }
          }
          opciones {
            tituloBanner
            titulo
            tipoTitulo
            imagenPrincipal {
              title
              file {
                url
                fileName
              }
            }
            redireccionBoton
            tituloBoton
            referenciaContenido {
               tituloBanner
               titulo
               imagenPrincipal {
                 title
                 file {
                   url
                   fileName
                 }
               }
               redireccionBoton
               tipoTitulo
            }
          }
          ocultarBotonera
        }
      }
    }
   `)

   const { inicioSesion, logoMagenta, logoWithe, opciones, ocultarBotonera } = data.allContentfulMenuLoggin.nodes[0];
   const [showFontSize, setShowFontSize] = useState(false);
   const [buttonMenu, setButtonMenu] = useState(false);
   const {refElement, isScrollActive} = useScrollDetect();
   const { isBrowser, window } = useBrowserMode();
   const [optionMenu, setOptionMenu] = useState(false);
   const [people, setPeople]: any = useState([])
   const [companies, setCompanies]: any = useState([])
   const [checked, setChecked] = useState(false) 

   useEffect(() => {
      opciones[0].referenciaContenido.map(card => {
           if (card.tipoTitulo === "personas") {
               setPeople(people => [...people, card])
           } else {
               setCompanies(companies => [...companies, card])
           }
       })
   }, [])

   const menuOptions = useRef(null);

   const isModeOpen = props.isMenuActive || props.isSearcherActive || props.isNotRoot || isScrollActive;
   const modeShadow = (!props.isMenuActive && props.isNotRoot && !props.isSearcherActive) || (isScrollActive && !props.isMenuActive && !props.isSearcherActive);

   const handleOutsideClick = () => {
      setOptionMenu(false);
      setButtonMenu(false);
   }

   const openMenu = () => {
      toogleFreeze(true);
      props.setMenuActive(true)
      props.setSearcherActive(false)
	}
	const closeMenu = () => {
		toogleFreeze(false);
      props.setMenuActive(false)
   }

   const handleSearcher = () => {
      toogleFreeze(!props.isSearcherActive);
      props.setSearcherActive(!props.isSearcherActive)
      props.isMenuActive && props.setMenuActive(false)
   }

   const toogleFreeze = ( isToggle:boolean ) => {
		isToggle ? document.body.classList.add('freeze-page') : document.body.classList.remove('freeze-page')
   }

   useEffect( ()=> {
      props.scrollActive.set(isScrollActive);
   }, [isScrollActive] )

   const renderBurguerButton = () => {
      const burguerButton = <ButtonBurger
         isMenuActive={ props.isMenuActive }
         isSearcherActive={ props.isSearcherActive }
         closeMenu={ closeMenu }
         openMenu={ openMenu }
         isNotRoot={ isModeOpen }
         lastDirection={ props.lastDirection }
         hideHeaderDesktop={ props.hideHeaderDesktop }
      />

      if (isBrowser && window.screen.width < 720 && props.isSearcherActive) return <React.Fragment />

      return burguerButton;
   }

   const renderLoginButton = ( { login, logout, updateProfile, gigyaAccount }: LoginGigyaInterface ) => {
      const loginButton = <Login
         isMenuActive={isModeOpen && !props.glassHeader}
         title={inicioSesion}
         login={login}
         logout={logout}
         updateProfile={updateProfile}
         gigyaAccount={gigyaAccount}
      />

      if ( isBrowser && window.screen.width < 720 && props.isSearcherActive) return <React.Fragment />

      return loginButton;
   }

   const handleClick = () => {
      setOptionMenu(!optionMenu)
      setButtonMenu(!buttonMenu)
   }

   const portalIcon = (open, condition) => {
      if ( !condition ) {
         return (
            <div className={`m-header__container-action__option__main__icon ${!open ? 'icon-white' : ''}`}>
               <i className='icon-ellipsis-v' />
               <i className='icon-ellipsis-v' />
               <i className='icon-ellipsis-v' />
            </div>
         )
      } else {
         return (
            <i className='icon-close' />
         )
      }
   }

   return (
      <GlobalContext.Consumer>
         {data => (
            <header
               ref={refElement}
               className={
                  `m-header${ isModeOpen ? ' m-header-open' : '' }` +
                  `${ props.isSearcherActive || props.isMenuActive ? ' m-header-open__searcher' : ''}` +
                  `${ modeShadow ? ' m-header-shadow' : ''}` +
                  `${ props.hideHeaderMobile && isScrollActive && props.lastDirection > 0 ? ' m-header__hide-mobile' : '' }` +
                  `${ props.hideHeaderDesktop && !props.isMenuActive && !props.isSearcherActive /* && isScrollActive */ && props.lastDirection > 0 ? ' m-header__hide-desktop' : '' }` +
                  `${ props.glassHeader ? ' m-header-glass' : ''}` +
                  `${ props.hideBurger ? ' m-header-noburger' : ''}`
               }
            >
               { !props.hideBurger
                  ? renderBurguerButton()
                  : <></>
               }
               <div className={`m-header__container ${props.isSearcherActive && 'm-header__container--search'}`} ref={refElement} >
                  <div className='m-header__cover' >
                     <a href='/' rel="nofollow noreferrer" className='gtmMenuTransLogoComfama' >
                        <img
                           loading="lazy"
                           className='m-header__cover__img gtmMenuTransLogoComfama'
                           src={ (isModeOpen && !props.glassHeader ? logoMagenta : logoWithe ).file.url}
                           alt={ (isModeOpen && !props.glassHeader ? logoMagenta : logoWithe ).title}
                        />
                     </a>
                  </div>
                  <div className='m-header__container-action'>
                     {
                        ((!ocultarBotonera || ocultarBotonera === undefined || ocultarBotonera === null) && opciones) &&
                        opciones.map((opcion, key) => (
                           <div key={key} ref={menuOptions} className={`m-header__container-action__option ${props.isSearcherActive ? 'hide' : ''}`}>
                              <button type="button" aria-label='Portal de servicios' className='m-header__container-action__option__main' onClick={() => handleClick()} >
                                 <span>Portal servicios</span>
                                 { isBrowser && window.screen.width > 960 ?
                                    portalIcon(isModeOpen, false)
                                    :
                                    portalIcon(false, buttonMenu)
                                 }
                              </button>
                              {optionMenu &&
                                 <div className='m-header__container-action__option__menu'>
                                    <div className="m-header__container-action__option__menu__switcher">
                                       <button type="button" aria-label='Servicios para personas' className={`menu-switcher1 ${!checked && "menu-selected"}`} onClick={() => setChecked(false)}>{opcion.tipoTitulo.split(";")[0]}</button>
                                       <button type="button" aria-label='Servicios para empresas' className={`menu-switcher2 ${checked && "menu-selected"}`} onClick={() => setChecked(true)}>{opcion.tipoTitulo.split(";")[1]}</button>
                                    </div>
                                    <div className="m-header__container-action__option__menu__cards">
                                       {(checked ? companies : people).map((tab, index) => (
                                          <a href={tab.redireccionBoton} target="_blank" rel="noreferrer" className='m-header__container-action__option__menu__cards__card' key={index}>
                                             <div className='m-header__container-action__option__menu__cards__card__container'>
                                                <img className='img-menu--no-hover' src={tab.imagenPrincipal[0].file.url} alt="" />
                                                <img className='img-menu--hover' src={tab.imagenPrincipal[1].file.url} alt="" />
                                                <span className='title-span'>{tab.tituloBanner}</span>
                                             </div>
                                          </a>
                                       ))}
                                    </div>
                                    {opcion.redireccionBoton &&
                                       <div className='menu-button'>
                                          <Button
                                             id={ 'buttonOpcionMenu-' + key }
                                             type='link'
                                             href={ !checked ? opcion.redireccionBoton.split(";")[0] : opcion.redireccionBoton.split(";")[1] }
                                             classname='primary'
                                             text={ opcion.tituloBoton }
                                             isDisabled={ false }
                                          />
                                       </div>
                                    }
                                 </div>
                              }
                              {
                                 outsideClick(menuOptions, handleOutsideClick)
                              }
                           </div>
                        ))
                     }
                     <button
                        type="button"
                        aria-label="Tamaño de fuente"
                        onClick={() => setShowFontSize(!showFontSize) }
                        className="m-header__small-btn"
                     >
                        <i className='hide-mobile icon icon-font-change-2 gtmMenuTransTamanoLetra' />
                     </button>
                     <div
                        className={`float-box ${showFontSize ? 'show': '' }`}
                     >
                        {showFontSize && <FontSize setShowFontSize={setShowFontSize} />}
                     </div>
                     <button
                        type="button"
                        aria-label="Modo oscuro"
                        onClick={props.actionTheme}
                        className="m-header__small-btn"
                     >
                        <i
                           className={`hide-mobile icon ${ props.isDark ? 'icon-sun gtmMenuTransTonalidadFondoBlanco' : 'icon-moon gtmMenuTransTonalidadFondoNegro' }`}
                        />
                     </button>
                     <button
                        type="button"
                        aria-label="Buscar en comfama.com"
                        onClick={() => handleSearcher()}
                        className="m-header__small-btn"
                     >
                        <i className={`icon ${props.isSearcherActive ? 'icon-close' : 'icon-search'} gtmMenuTransBuscar`} />
                     </button>
                     {props.hideLoggin === undefined || !props.hideLoggin || props.hideLoggin === null ?
                        renderLoginButton( data.gigyaContext ) : console.log
                     }
                  </div>
               </div>
            </header>
         )}
      </GlobalContext.Consumer>
   )
}

export default Header;
