import React from 'react';

const HeadboardNull = () => {
   return (
      <div className='o-headboard-null' >
      </div>
   )
}

export default HeadboardNull;
