import React, { useState, useEffect } from "react";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import NavAccordion from "../../atoms/nav-accordion/navAccordion";
import FontSize from "../../atoms/font-size/fontSize";
import Searcher from "../../molecules/searcher/searcher";
import "./navComponent.scss";

const NavComponent = (props: NavComponentInterface) => {
   const data = props.data

   const {
      categorias,
      listaDerecha,
   } = data.allContentfulMenuCategorias.nodes[0];
   const footerCategories = data.allContentfulFooter.nodes[0].categorias;

   const { isMenuActive, isSearcherActive, sideLinks } = props;
   const { document } = useBrowserMode();

   const [activeItem, setActiveItem] = useState(0);
   const [isAnyActive, setIsAnyActive] = useState(true);
   const [showFontSize, setShowFontSize] = useState(false);

   useEffect(() => {
      const menu = document.querySelector('.m-nav-component')
      if(menu) menu.scrollTop = 0
   }, [isMenuActive === true])

   return (
      <nav
         className={`m-nav-component ${
         isMenuActive || isSearcherActive ? "" : "m-nav-component--hide"
         }`}
      >
         {isSearcherActive ? (
         <Searcher type="comfama"/>
         ) : (
         <React.Fragment>
            <div className="m-nav-component__accordion">
               {categorias &&
               categorias.map((cat, i) => (
                  <NavAccordion
                     key={`cat${i}`}
                     index={i}
                     category={cat}
                     activeItem={activeItem}
                     setActiveItem={setActiveItem}
                     isAnyActive={isAnyActive}
                     setIsAnyActive={setIsAnyActive}
                  />
               ))}
            </div>

            <div className="m-nav-component__sidelinks">
               <ul>
               {sideLinks &&
                  sideLinks.map((item, i) => (
                     <li key={`link${i}`}>
                     <a
                        href={item.hyperLink ? item.hyperLink : '/'+item.slug}
                        target={ item.hyperLink ? "_blank" : "_self" }
                        rel={ item.hyperLink ? "nofollow noreferrer" : 'follow' }
                     >
                        <img
                           loading="lazy"
                           src={item.imagenDestacada && item.imagenDestacada[0].file.url}
                           alt={item.imagenDestacada[0].description}
                        />
                     </a>
                     </li>
                  ))}
               <li>
                  <i
                     className="icon__option icon-font-change-2"
                     onClick={() => setShowFontSize(!showFontSize)}
                  />
               </li>
               <li>
                  <i 
                     className={`icon__option ${ props.isDark ? 'icon-sun gtmMenuTransTonalidadFondoBlanco' : 'icon-moon gtmMenuTransTonalidadFondoNegro' }`} 
                     onClick={props.actionTheme}
                  />
               </li>
               </ul>
            </div>

            <div className={`float-box ${showFontSize ? "show" : ""}`}>
               <FontSize setShowFontSize={setShowFontSize} />
            </div>

            <div
               className={`m-nav-component__links ${
               !isAnyActive ? "m-nav-component__links--locked" : ""
               }`}
            >
               <ul className="m-nav-component__links-list">
               {listaDerecha &&
                  listaDerecha.map((link, i) => (
                     <li
                     key={`cat${i}`}
                     className={`gtmMenuTransLateral${normalizeTwoWords(
                        link.nombreCategoria
                     )}`}
                     >
                     <a
                        className={`gtmMenuTransLateral${normalizeTwoWords(
                           link.nombreCategoria
                        )}`}
                        href={link.hyperLink || '/'+link.slug}
                        target={ link.hyperLink ? "_blank" : "_self" } //Temporal. Remove this when new contact center is avaliable
                        rel={link.hyperLink ? "nofollow noreferrer" : 'follow'}
                     >
                        {" "}
                        {link.nombreCategoria}{" "}
                     </a>
                     </li>
                  ))}
               </ul>

               <div className="m-nav-component__links-social">
               <p>Síguenos en nuestras redes</p>
               <ul>
                  {footerCategories[footerCategories.length - 1].nombreCategoria.includes("Síguenos")
                  && footerCategories[footerCategories.length - 1].referenciaSubcategorias
                  && footerCategories[footerCategories.length - 1].referenciaSubcategorias.map((item, i) => (
                     <li key={`item${i}`}>
                        <a
                           className={`${
                           item.nombreSubcategoria
                           } gtm${normalizeTwoWords(item.titulo)}`}
                           href={item.link}
                           target="_blank"
                           rel="nofollow noreferrer"
                           aria-label={item.titulo}
                        ></a>
                     </li>
                     ))}
               </ul>
               </div>
            </div>
         </React.Fragment>
         )}
      </nav>
   );
};

interface NavComponentInterface {
   isMenuActive: boolean;
   isSearcherActive: boolean;
   sideLinks: any;
   isDark: boolean;
   actionTheme: () => void;
   data: any;
}

export default NavComponent;
