import React, { useState, useEffect, useMemo } from "react";
import { ReferenciaCabecera } from "../../../interfaces/ReferenciaCabecera";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useScrollDetect from "../../../shared/hooks/useScrollDetect";
import ShareModal from "../../molecules/share-modal/shareModal";
import { getFormatDate } from "../../../shared/utils/dateUtilities";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";
import "./headboardButtons.scss";

const HeadboardButtons = (props: ReferenciaCabecera) => {
   const { isBrowser, window, document } = useBrowserMode();
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { isScrollActive, refElement } = useScrollDetect();
   const [scrollPercent, setScrollPercent] = useState(0);

   let timerScroll: any;

   const onScrollAction = () => {
      window.clearTimeout(timerScroll);
      timerScroll = setTimeout(() => {
         let windowScroll = window.pageYOffset;
         let contentHeight = document.body.offsetHeight - 1000;
         setScrollPercent((windowScroll * 100) / contentHeight);
      }, 66);
   };

   const arrayBreadCrumb = props.migaDePan.split("/");
   const gtmCategory =
      arrayBreadCrumb && arrayBreadCrumb.length > 0 ? arrayBreadCrumb[2] : "";

   const doCssChatBot = () => {
      const chatBot = document.body.querySelector(
         ".RecastAppExpander.CaiAppExpander.open"
      );
      chatBot?.classList.add("open-article");
   };

   useEffect(() => {
      isBrowser && document.addEventListener("scroll", onScrollAction);
      doCssChatBot();

      return () => {
         isBrowser && document.removeEventListener("scroll", onScrollAction);
      };
   }, []);

   useMemo(() => {
      props.superFooter?.set(isScrollActive);
   }, [isScrollActive]);

   const toggleModal = () => {
      setIsModalVisible(!isModalVisible);
   };

   return (
      <div className="o-headboard-btns" ref={refElement}>
         <Breadcrumb content={props.migaDePan} />

         <div className="o-headboard-btns__cont">
            <div className="o-headboard-btns__cont__content">
               <span className="o-headboard-btns__cont__content__title-span">
                  {props.tituloSpan}
               </span>
               <h1 className="o-headboard-btns__cont__content__title">
                  {props.tituloPrincipal}
               </h1>
               <span className="o-headboard-btns__cont__content__date">
                  {getFormatDate(props.updatedAt, 0)}
               </span>
               {props.descripcion && (
                  <article className="o-headboard-btns__cont__content__text">
                     {props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
                  </article>
               )}
            </div>
            <div className="o-headboard-btns__cont__cover">
               {props.imagenHeader.fluid ?
                  <Img
                     className="o-headboard-btns__cont__cover__img"
                     fluid={props.imagenHeader.fluid}
                     alt={props.imagenHeader.title || "imagen cabecera botones"}
                  />
                  :
                  <img
                     className="o-headboard-btns__cont__cover__img"
                     src={OptimizeImage(props.imagenHeader.file.url, props.imagenHeader.file.contentType, props.imagenHeader.file.details.image.width > 768 ? 768 : props.imagenHeader.file.details.image.width)}
                     alt={props.imagenHeader.title || "imagen cabecera botones"}
                     loading="lazy"
                  />
               }
            </div>
         </div>

         <div
            className={`o-headboard-btns__tabs ${
               isScrollActive ? "o-headboard-btns__tabs--fixed" : ""
            }`}
         >
            <div
               className={`o-headboard-btns__tabs-title ${
                  isScrollActive ? "o-headboard-btns__tabs-title--shown" : ""
               }`}
            >
               <span> {props.tituloPrincipal} </span>
            </div>
            <ul className="o-headboard-btns__tabs-btns">
               <li>
                  <button
                     className={`o-headboard-btns__tabs-btns-btn
                        gtm${normalizeTwoWords(
                           gtmCategory
                        )}Articulo${normalizeTwoWords(
                        props.tituloPrincipal
                     )}Compartir
                     `}
                     onClick={toggleModal}
                     aria-label='Compartir'
                  >
                     <i
                        className={`icon-share
                        gtm${normalizeTwoWords(
                           gtmCategory
                        )}Articulo${normalizeTwoWords(
                           props.tituloPrincipal
                        )}Compartir
                     `}
                     ></i>
                     {!isScrollActive &&
                        `${props.botonCompartir || "Compartir"}`}
                  </button>
               </li>
            </ul>
            <div className={`o-headboard-btns__tabs-scrollbar`}>
               <span style={{ width: `${scrollPercent}%` }}></span>
            </div>
         </div>

         <ShareModal 
            open={isModalVisible} 
            onClose={toggleModal} 
            gtmClass={`gtm${normalizeTwoWords(
                  gtmCategory
               )}Articulo${normalizeTwoWords(
                  props.tituloPrincipal
               )}Compartir`
            }
         />
      </div>
   );
};

export default HeadboardButtons;
