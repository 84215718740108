import React from 'react';
import './headboardClaustro.scss';
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera';
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";


const HeadboardClaustro = (props:ReferenciaCabecera) => {
   return (
      <div className='o-headboard-claustro' >
         <div className='o-headboard-claustro__titles' >
            { props.tituloPrincipal &&
               <h1 className='o-headboard-claustro__titles__title' ><span>{props.tituloPrincipal.split(' ')[0]}</span><br />{props.tituloPrincipal.split(' ')[1]}</h1>
            }            
            { props.tituloSpan &&
               <span className='o-headboard-claustro__titles__span' >{props.tituloSpan}</span>
            }
         </div>
         {props.imagenHeader &&
            <div className='o-headboard-claustro__cover' >
               {props.imagenHeader.fluid ?
                  <Img
                     className="o-headboard-claustro__cover__img"
                     fluid={props.imagenHeader.fluid}
                     alt={props.imagenHeader.title || "imagen cabecera botones"}
                  />
               :
                  <img  className='o-headboard-claustro__cover__img'
                        src={OptimizeImage(props.imagenHeader.file.url, props.imagenHeader.file.contentType, 550)}
                        alt={ props.imagenHeader && props.imagenHeader.title || "imagen cabecera" }
                        loading="lazy"
                  />
               }
            </div>
         }
         <div className='o-headboard-claustro__blur'></div>
      </div>
   )
}

export default HeadboardClaustro;
