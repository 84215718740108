import firebase from "gatsby-plugin-firebase";

export const isIOS = () => {
  return ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
  ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document))
}

export const initFirebase = () => {
  firebase.messaging().getToken({ vapidKey: "BJkaELuhTyhdBfqMACOBv8FjMu0cgTJZIxprS25rPFAblm6RlRVG5DMMOa8NjPsskG4KIhdT_qNKmXDavSQUdoI"})
    .then(token => {
      sessionStorage.setItem("TokenFCM", "1");
      // console.log("Goood", token)
      let apikey = "key=AAAAxCwHD44:APA91bFbWNzgtmsIWhUIqD9xl4mukFYAI1Ky7LaU-athYfR9KmERLJnoBmJ1M0D65DJ9ku-y-0JDUyqdjOuZxQDGM2lkPbsMYK18N-dt3u8o2V-QUJXnvth2SPSkYF01AcoSZb1_i2Mt"
      let url = 'https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/com-notifications';
      let data = {
        method: "POST",
        headers: { 'Authorization': apikey },
        body: JSON.stringify({})
      };
      // let config = {  }
      fetch(url, data).then(async res => console.log(await res.json())).catch(error => console.error('Error:', error))
    })
    .catch(err => console.log("Error getting Token", err))
}

export const receiveNotifications = () => {
  firebase.messaging().onMessage(function(payload) {
    // console.log('[firebase-messaging-sw.js] Received background message ', payload);
    // Customize notification here
    const notificationOptions = {
      body: payload.data.message,
    };

    // self.registration.showNotification(notificationTitle,
    //   notificationOptions);
    navigator.serviceWorker.getRegistration().then(reg => reg.showNotification(payload.data.title, notificationOptions))
  });
}
