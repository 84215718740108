import React from 'react'
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera'
import './headboardDientelandia.scss'

const HeadboardDientelandia = (props: ReferenciaCabecera) => {
    return (
        <div className='o-headboard-dientelandia'>
            <h1 className='o-headboard-dientelandia__title'>{props.tituloPrincipal}</h1>
            <img className='o-headboard-dientelandia__bg hide-on-medium' src={props.imagenesArboles[0].file.url} alt="" />
            <img className='o-headboard-dientelandia__bg--mobile show-on-medium' src={props.imagenesArboles[2].file.url} alt="" />
            <img className='o-headboard-dientelandia__main' src={props.imagenHeader.file.url} alt="" />
        </div>
    )
}

export default HeadboardDientelandia
