import { useEffect, useRef, useState } from "react";
import useBrowserMode from "./useBrowserMode";

const useScrollDetect = ()  => {
/* Zone detect scroll */
   const { isBrowser, document, window } = useBrowserMode();
   const refElement = useRef<any>();
   const [isScrollActive, setIsScrollActive] = useState<boolean>(false);
   let timerScroll:any;
   const onScrollAction = () => {
      window.clearTimeout( timerScroll );
      timerScroll = setTimeout(()=> {
         
         const el: HTMLElement = refElement.current;
         const boundElement = el.getBoundingClientRect();
         // console.log( 'elemento', boundElement.top );
         // console.log('window', window.pageYOffset, window.pageYOffset >= (boundElement.top + boundElement.height)  );
         setIsScrollActive(window.pageYOffset > 0 && window.pageYOffset >= (boundElement.top + boundElement.height) );
      }, 66);
   }
   useEffect( () => {
      if( isBrowser ) {
         document.addEventListener('scroll', onScrollAction);
      }
      return () => {
         isBrowser && document.removeEventListener('scroll', onScrollAction);
      }
   },[])
   return { refElement, isScrollActive };
/* ZoneClose detect scroll */
}

export default useScrollDetect;

