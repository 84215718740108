import React from 'react';
import './headboard.scss';
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords, findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Breadcrumb from '../../atoms/breadcrumb/breadcrumb';
import Img from "gatsby-image";


const Headboard = (props:ReferenciaCabecera) => {
   return (
      <div className='o-headboard' >
         
         <Breadcrumb content = { props.migaDePan } />
         
         <div className='o-headboard__container' >
            <div className='o-headboard__container__content'>
               { props.tituloPrincipal &&
                  <h1 className='o-headboard__container__content__title' >{props.tituloPrincipal}</h1>
               }
               { props.descripcion &&
                  <article className='o-headboard__container__content__text' >{props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}</article>
               }
               { props.referenciaTags &&
                  <div className='o-headboard__container__content__tags' >
                     {props.referenciaTags.map( (value, index) => (
                        <a 
                           key={index}
                           className={`o-headboard__container__content__tags__tag gtm${normalizeTwoWords(props.tituloPrincipal)}Tag${normalizeTwoWords(value.nombreTag)}`}
                           title={value.nombreTag} 
                           href={value.slugSubcategorias}
                           target={ findTarget(value.slugSubcategorias) }
                           rel={ findRel(value.slugSubcategorias)}
                        >{value.nombreTag}</a>
                     ))}
                  </div>
               }
            </div>
            {props.imagenHeader ?
               <div className='o-headboard__container__cover' >
                  {props.imagenHeader.fluid ?
                     <Img
                        className="o-headboard__container__cover__img"
                        fluid={props.imagenHeader.fluid}
                        alt={props.imagenHeader.title || "imagen cabecera botones"}
                     />
                  :
                     <img  className='o-headboard__container__cover__img'
                           src={OptimizeImage(props.imagenHeader.file.url, props.imagenHeader.file.contentType, 550)}
                           alt={ props.imagenHeader && props.imagenHeader.title || "imagen cabecera" }
                           loading="lazy"
                     />
                  }
               </div>
               : null
            }

         </div>
      </div>
   )
}

export default Headboard;
