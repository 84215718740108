import React, { useState } from 'react';
import './login.scss';
import { GigyaAccount } from '../../../shared/interfaces/GigyaAccount';
import { firstUpperOnly } from '../../../shared/utils/stringUtilities';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';


interface LoginInterface {
   title: string;
   isMenuActive?:boolean;
   login: () => void;
   logout: () => void;
   updateProfile: () => void;
   gigyaAccount?: GigyaAccount;
}

const Login = (props:LoginInterface) => {

   const { isBrowser, document, window} = useBrowserMode();
   const [isShow, setIsShow] = useState(false);
   const gigyaAccount = props.gigyaAccount;
   const options = [
      {
         title: 'Ir a mi perfil',
         icon: 'icon-user-circle1',
         gtm: 'gtmMenuTransPerfilUsuarioIrAMiPerfil',
         onAction: () => {
            doShow(false);
            props.updateProfile();
         }
      },{
         title: 'Servicios en linea',
         icon: 'icon-tv',
         gtm: 'gtmMenuTransPerfilUsuarioServiciosLinea',
         onAction: () => {
            if( isBrowser ) {
               doShow(false);
               window.open('https://serviciosenlinea.comfama.com/', '_blank');
            }
         }
      }, {
         title: 'Cerrar sesión',
         icon: 'icon-sign-out',
         gtm: 'gtmMenuTransPerfilUsuarioCerrarSesion',
         onAction: () => {
            props.logout();
            doShow(false);
            localStorage.removeItem('uid-pre');
         }
      }
   ]

   const doShow = ( isShow:boolean ) => {
      if(isBrowser && isShow ) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.add('freeze-page')
      } else if(isBrowser) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.remove('freeze-page')
      }
      setIsShow(isShow);
   }

   return (
      <div className='a-login' >
         { gigyaAccount ?
         <div className={`a-login__container ${ props.isMenuActive ? 'a-login__container-open' : '' }`} >
            <span className='a-login__container__title hide-mobile' >
               {`¡Hola ${firstUpperOnly(gigyaAccount.profile.firstName)} ${firstUpperOnly(gigyaAccount.profile.lastName)}!`}
            </span>
            <i 
               className={`a-login__container__icon ${ isShow ? 'icon-caret-up' : 'icon-caret-down'} hide-mobile gtmMenuTransPerfilUsuario`} 
               onClick={ () => doShow(!isShow) }
            />
            <div 
               className={
                  `a-login__container__circle 
                  ${ isShow ? 'a-login__container__circle-open' : ''} 
                  ${ 
                     gigyaAccount.profile?.gender === 'f' ? 'circle-female' :
                     gigyaAccount.profile?.gender === 'm' ? 'circle-male' : ''  
                  }  gtmMenuTransPerfilUsuario` 
               }
               onClick={ () => doShow(!isShow) }
            >
               { (gigyaAccount.profile && gigyaAccount.profile?.thumbnailURL) ?
                  <div className='a-login__container__circle__cover gtmMenuTransPerfilUsuario' >
                     <img
                        loading="lazy"
                        className='a-login__container__circle__cover__img gtmMenuTransPerfilUsuario'
                        alt={gigyaAccount.profile.firstName} 
                        src={gigyaAccount.profile.thumbnailURL} 
                     />
                  </div>
                  :
                  `${gigyaAccount.profile?.firstName.charAt(0)}${gigyaAccount.profile?.lastName.charAt(0)}`
               }
            </div>
            { isShow && 
            <div className='a-login__container__float-box' >
               <ul className='a-login__container__float-box__options' >
                  {options.map( (value, index) => (
                     <li
                        onClick={value.onAction}
                        className={`a-login__container__float-box__options__option ${value.gtm}`}
                        key={index} 
                     >
                        <i className={`a-login__container__float-box__options__option__icon ${value.icon} ${value.gtm}`} />
                        {value.title}
                     </li>
                  ) ) }
               </ul>
               <div className='a-login__container__float-box__background' onClick={ () => doShow(false) } ></div>
            </div>
            }
         </div>
         :
         <>
            <i 
               className={`a-login__icon icon-user hide-desktop gtmMenuTransIniciarSesion`}
               onClick={props.login}
            />
            <button 
               className={`a-login__title hide-mobile ${ props.isMenuActive ? 'a-login__title-open' : '' } gtmMenuTransIniciarSesion`}
               onClick={props.login}
               aria-label="Iniciar sesión"
               type="button"
            >{props.title}</button>
         </>
         }  
      </div>
   )
}

export default Login;
