import React, { useState, useEffect, useMemo } from 'react'
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import useScrollDetect from '../../../shared/hooks/useScrollDetect'
import useDirectionScroll from '../../../shared/hooks/useDirectionScroll'
import Breadcrumb from '../../atoms/breadcrumb/breadcrumb'
import Searcher from '../../molecules/searcher/searcher'
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image"
import './headboardSalaPrensa2.scss'

const HeadboardSalaPrensa2 = (props:ReferenciaCabecera) => {

   const { isBrowser, window, document } = useBrowserMode();
   const [tabSelect, setTabSelect] = useState(-1);
   const [upScroll, setUpScroll] = useState(false);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const { isScrollActive, refElement } = useScrollDetect();
   const { lastDirection } = useDirectionScroll();

   const arrayBreadCrumb = props.migaDePan.split("/");
   const gtmCategory =
      arrayBreadCrumb && arrayBreadCrumb.length > 0 ? arrayBreadCrumb[2] : "";

   const refTags = (props.referenciaTags || []).map( rt => rt.localizador );

   const goToContent = (index:number ) => {
      if ( isBrowser && refTags.length ) {
         const element = document.getElementById(props.tipoCabecera+refTags[index][0]);
         setUpScroll(true);
         element?.scrollIntoView(true);
      }
   }

   let timerScroll:any;

   const verifyTabs = (index:number) => {
      const indexString = `${index}`
      for(let i = 0; i < refTags.length; i++ ) {
         const indexSearch = refTags[i].find( rt => rt === indexString );
         if( indexSearch !== undefined) {
            setTabSelect(i);
            return;
         }
      }
      setTabSelect(-1);
   } 

   const onScrollAction = () => {
      window.clearTimeout( timerScroll );
      timerScroll = setTimeout(() => {
         if( upScroll ) {
            window.scroll(0, window.pageYOffset - ( isScrollActive ? 138 : 220 ) );
            setUpScroll(false);
         }
         const tabs = document.querySelectorAll('.o-headboard-sala-prensa__tabs__tab');
         const items = document.querySelectorAll(`.${props.tipoCabecera}`);
         for( let i = 0; i < items.length; i++ ) {
            const bottom = items[i].getBoundingClientRect().bottom;
            const top = items[i].getBoundingClientRect().top;
            if( bottom > 139 && top <= 139 ) {
               verifyTabs(i);
               tabs.item(i)?.scrollIntoView(true);
               return;
            }
         } 
         setTabSelect(-1);
      }, 66);
   }

   useEffect(() => {
      isBrowser && document.addEventListener('scroll', onScrollAction);
      return () => {
         isBrowser && document.removeEventListener('scroll', onScrollAction);
      }
   }, [upScroll] );

   useMemo(() => {
      props.superFooter?.set(isScrollActive);
   }, [isScrollActive])

   const toggleModal = () => {
      setIsModalVisible(!isModalVisible);
   }

   const isTabsNoShare = !props.tituloBoton && !props.botonCompartir;
   return (
      <div className="o-headboard-sala-prensa2" ref={refElement} >
         <div className="o-headboard-sala-prensa2__breadcrumb">
            <Breadcrumb content={props.migaDePan} />
         </div>
         <div className="o-headboard-sala-prensa2__container">
            <div className="o-headboard-sala-prensa2__container__cover">
            {props.imagenHeader.fluid ?
               <Img
                  fluid={props.imagenHeader.fluid}
                  alt={props.imagenHeader.title || "imagen cabecera botones"}
               />
            :
               <img
                  src={OptimizeImage(props.imagenHeader.file.url, props.imagenHeader.file.contentType, props.imagenHeader.file.details.image.width)}
                  alt={ props.imagenHeader.title || "imagen cabecera sala de prensa"}
                  loading="lazy"
               />
            }
            </div>
            <div className="o-headboard-sala-prensa2__container__info">
               <h1 className="o-headboard-sala-prensa2__container__info__title">
                  {props.tituloPrincipal}
               </h1>
               <p className="o-headboard-sala-prensa2__container__info__subtitle">
                  {props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
               </p>
            </div>
            <div className="o-headboard-sala-prensa2__container__searcher">
               <Searcher type="headboard"/>
            </div>
            <img
               src="https://images.ctfassets.net/gkhyeghj07ak/5Vizab2mK8WYTeCY3ub7yD/155eda9a7f491aaddf97f95a130c8dd3/1214978_mouse_scroll_icon__1_.svg"
               alt="scroll down"
               onClick={ () => window.scroll(0, 600) }
            />
         </div>
         { isScrollActive &&
            <div className={`o-headboard-sala-prensa2__top${isTabsNoShare ? ' o-headboard-sala-prensa2__top--hide-m' : ''}`} >
               <span className='o-headboard-sala-prensa2__top__title' >{props.tituloPrincipal}</span>
               <div className='o-headboard-sala-prensa2__top__buttons' >
                  { props.botonCompartir &&
                     <button 
                        className={`o-headboard-sala-prensa2__top__buttons__button-two icon-share gtm${normalizeTwoWords(
                              gtmCategory
                           )}Articulo${normalizeTwoWords(
                              props.tituloPrincipal
                           )}Compartir`
                        } 
                        onClick={toggleModal}
                     ></button>
                  }
               </div>
            </div>
         }
         { props.referenciaTags &&
            <ul className={`o-headboard-sala-prensa2__tabs${isScrollActive ? ` tabs-fixed` : ''}${lastDirection == -1 ? ' tabs-fixed-move':'' }`} >
               {props.referenciaTags.map( (value, index) => (
                  <li
                     key={`headerTab-${index}`}
                     className={`gtmConoceComfamaSuperior${normalizeTwoWords(value.nombreTag)} o-headboard-sala-prensa2__tabs__tab${index === tabSelect ? ` tab-active` : ''}`}
                     onClick={ () => goToContent(index) }
                  >{value.nombreTag}</li>
               ))}
            </ul>
         }
      </div>
   )
}

export default HeadboardSalaPrensa2;
