import React from 'react';
import './fontSize.scss';
import GlobalContext from '../../../contexts/globalContext';

interface IFontSize {
   setShowFontSize: ( value:boolean ) => void
}


const FontSize = (props:IFontSize) => {

   const changeFontSize = ( action: string, fontSize:any ) => {
      if(typeof window !== `undefined`) {
         const item = document.querySelector('html') || { style: {fontSize} };
         switch (action) {
            case '+' :
               if( fontSize?.get < 120 ) {
                  item.style.fontSize = `${fontSize.get+20}%`;
                  fontSize.set(fontSize.get+20);
               }
            break;
            case '-' :
               if( fontSize?.get > 80 ) {
                  item.style.fontSize = `${fontSize.get-20}%`;
                  fontSize.set(fontSize.get - 20);
               }
            break;
         }
      }
   }

   return (
      <GlobalContext.Consumer>
      { data => (
         <div className='a-font-size' >
            <div className='a-font-size__background' onClick={()=> props.setShowFontSize(false)} />
            <div className='a-font-size__container' >
               <div className='a-font-size__top' >
                  <i className='a-font-size__top__small icon-font'></i>
                  <span
                     className='a-font-size__top__label text-mobile'  
                     onClick={() => changeFontSize('-', data.fontSize)}
                  >Disminuir</span>
                  <div className='a-font-size__top__label label-mobile' >{data.fontSize?.get}%</div>
                  <span 
                     className='a-font-size__top__label text-mobile'
                     onClick={() => changeFontSize('+', data.fontSize)}
                  >Aumentar</span>
                  <i className='a-font-size__top__large icon-font'></i>
               </div>
               <div className='a-font-size__bottom' >
                  <button 
                     className='a-font-size__bottom__small'  
                     onClick={() => changeFontSize('-', data.fontSize)}
                     aria-label='Disminuir tamaño de letra'
                  >Disminuir</button>
                  <button 
                     className='a-font-size__bottom__large'
                     onClick={() => changeFontSize('+', data.fontSize)}
                     aria-label='Aumentar tamaño de letra'
                  >Aumentar</button>
               </div>
            </div>
         </div>
      )}
      </GlobalContext.Consumer>
   )
}

export default FontSize;
