import { MetaSearchInterface } from "../interfaces/MetaSearchInterface";
import { useState, useEffect, useMemo } from "react";

const useMetaSearch = (
  meta: "comfama" | "preguntas" | "biblioteca" | "bibliotecaComunidadesEmpleo" | "bibliotecaPersonasEmpleo" | "foroEmpleo" | "autores" | "empleo" | "redamor"  = "comfama" 
) => {
  const [metaSearch, setMetaSearch] = useState<MetaSearchInterface[] | null>(
    null
  );
  const [filterSearch, setFilterSearch] = useState<
    MetaSearchInterface[] | null
  >(null);

  const [filterPage, setFilterPage] = useState<MetaSearchInterface[]>([]);
  const [paginator, setPaginator] = useState<number>(-1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [lastSearch, setLastSearch] = useState<string | null>(null);
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC");

  const jsonSearch = () => {
    switch (meta) {
      case "comfama":
        return "/metas-mf/meta-comfama.json";
      case "preguntas":
        return "/metas-mf/meta-preguntas.json";
      case "biblioteca":
        return "/bibliotecas/metas-mf/meta-biblioteca.json";
      case "autores":
        return "/bibliotecas/metas-mf/meta-autores.json";
      case "redamor":
        return "/red-de-amor/metas-mf/meta-redamor.json";
      case "empleo":
        return "/servicio-de-empleo/metas-mf/meta-empleo.json";
      case "bibliotecaComunidadesEmpleo":
        return "/servicio-de-empleo/metas-mf/meta-biblioteca-comunidades.json";
      case "bibliotecaPersonasEmpleo":
        return "/servicio-de-empleo/metas-mf/meta-biblioteca-personas.json";
      case "foroEmpleo":
        return "/servicio-de-empleo/metas-mf/meta-foro.json";
      default:
        return "/metas-mf/meta-comfama.json";
    }
  };

  useEffect(() => {
    if (!metaSearch) {
      const _get = fetch(jsonSearch());
      _get.then(async (resp) => {
        const metadata = await resp.json();
        setMetaSearch(metadata);
      });
    }
    doFilter(lastSearch!);
  }, [lastSearch, limit, order, metaSearch]);

  const doFilter = (search: string) => {
    if (!metaSearch || !search || search.length < 3) {
      setFilterSearch(null);
      setFilterPage([]);
      setPaginator(-1);
      setTotalPages(0);
    } else {
      const filter = metaSearch
        .filter((meta) => meta.indexSearch.indexOf(search.toLowerCase()) > -1)
        .sort(fSort[order]);
      const nPages = filter.length / limit;
      setFilterSearch(filter);
      setTotalPages(
        nPages - Math.floor(nPages) !== 0 ? Math.ceil(nPages) : nPages
      );
      setFilterPage(filter.slice(0, limit));
      setPaginator(0);
    }
  };

  const fSort = {
    ASC: (a: MetaSearchInterface, b: MetaSearchInterface) =>
      a.fecha < b.fecha ? 1 : -1,
    DESC: (a: MetaSearchInterface, b: MetaSearchInterface) =>
      a.fecha > b.fecha ? 1 : -1,
  };

  useMemo(() => {
    if (paginator >= 0) {
      const slice = paginator * limit;
      if (filterSearch) setFilterPage(filterSearch.slice(slice, slice + limit));
    }
  }, [paginator]);

  const goNext = () => {
    goToPage(paginator + 1);
  };

  const goBack = () => {
    goToPage(paginator - 1);
  };

  const goToPage = (value: number) => {
    setPaginator(value);
  };

  const doSearch = (value: string) => {
    if (value !== lastSearch) {
      setLastSearch(value);
    }
  };

  return {
    metaSearch,
    filterSearch,
    filterPage,
    paginator,
    totalPages,
    goNext,
    goBack,
    goToPage,
    setLimit,
    doSearch,
    setOrder,
    order,
  };
};

export default useMetaSearch;
