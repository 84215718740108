import useBrowserMode from "./../shared/hooks/useBrowserMode";
import { KEYBE_CHAT_BOT_INTEGRATION_SRC } from "./chatConstants";
interface verifyChatInterface {
   verifyChat: (chatType: string) => void;
}

const useVerifyChat = (): verifyChatInterface => {
   const { document, window } = useBrowserMode();

   const hideDefaultChat = () => {
      document.getElementById("cai-webchat")?.remove();
      document.getElementById("cai-webchat-div")?.remove();
   };

   const showChatMentoria = () => {
      let head = document.head || document.getElementsByTagName("head")[0];
      let scriptIntegrationChat = document.createElement("script");
      scriptIntegrationChat.setAttribute("src", KEYBE_CHAT_BOT_INTEGRATION_SRC);
      head.appendChild(scriptIntegrationChat);
      let script = document.createElement("script");
      script.onload = () => {
         hideDefaultChat();
         var configChat = {
            apiKey: "" + process.env.KEYBE_CHAT_BOT_API_KEY,
         };
         window.keybe.webchatConversationsUiLoad(configChat).then((res) => {
            window.keybe.openChat();
            console.log("Opening CHATBOT");
         });
      };
      script.src = process.env.KEYBE_CHAT_BOT_SRC;
      head.appendChild(script);
   };

   const showChatFormMentoria = () => {
      let head = document.head || document.getElementsByTagName("head")[0];
      let script = document.createElement("script");
      script.onload = () => {
         var configChat = {
            apiKey: "280c898d5fe147eab327b5b86580c655" // TODO get this api from .env
         };
         window.keybe.webchatConversationsUiLoad(configChat).then((res) => {
            // window.keybe.openChat();
         });
      };
      script.src = process.env.KEYBE_CHAT_BOT_SRC;
      head.appendChild(script);
   };

   const verifyChat = (typeChat: string) => {
      switch (typeChat) {
         case "chatMentoria":
            showChatMentoria();
            break;
         case "chatFormMentoria":
            showChatFormMentoria();
            break;
         default:
            break;
      }
   };

   return { verifyChat };
};

export default useVerifyChat;
