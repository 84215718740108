import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button2 from "../../atoms/button2/button2";
import { CardActions, Grow } from "@material-ui/core";
import styles from "./headboardMain.module.scss";

export interface IHeadboardMainProps {
   title: string;
   subtitle: string;
   image: string;
   description: any;
   btnLabel: string;
   redirection?: string;
   bgImage?: string;
}

const HeadboardMain = ({
   subtitle,
   title,
   description,
   btnLabel,
   redirection,
   image,
   bgImage,
}: IHeadboardMainProps) => {
   return (
      <Grow in timeout={500} appear>
         <Card className={styles.o_headboard_main}>
            {bgImage && (
               <CardMedia
                  component="img"
                  image={bgImage}
                  alt={title}
                  className={styles.o_headboard_main__bg}
               />
            )}
            <Grid
               container
               spacing={2}
               className={styles.o_headboard_main__container}
            >
               <Grid
                  item
                  xs={12}
                  sm={6}
                  className={styles.o_headboard_main__info}
               >
                  <CardContent>
                     <Typography
                        variant="subtitle1"
                        className={styles.o_headboard_main__subtitle}
                     >
                        {subtitle}
                     </Typography>
                     <Typography
                        variant="h1"
                        className={styles.o_headboard_main__title}
                     >
                        {title}
                     </Typography>
                     <Typography
                        variant="body1"
                        className={styles.o_headboard_main__desc}
                     >
                        {description}
                     </Typography>
                     {redirection && (
                        <CardActions
                           className={styles.o_headboard_main__actions}
                        >
                           <Button2 redirect={redirection} label={btnLabel} />
                        </CardActions>
                     )}
                  </CardContent>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <CardMedia
                     component="img"
                     image={image}
                     alt={title}
                     className={styles.o_headboard_main__img}
                  />
               </Grid>
            </Grid>
         </Card>
      </Grow>
   );
};

export default HeadboardMain;
