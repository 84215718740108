import React from 'react'
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera'
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import './headboardInforme2022.scss'

const HeadboardInforme2022 = (props:ReferenciaCabecera) => {

    return (
        <div className='o-headboard-informe2022'>
            <img className='o-headboard-informe2022__bg-desktop' src={props.imagenesArboles[1].file.url} alt="" />
            <img className='o-headboard-informe2022__bg-mobile' src={props.imagenesArboles[1].file.url} alt="" />
            <h1 className='o-headboard-informe2022__title'>{props.tituloPrincipal}</h1>
            <span className='o-headboard-informe2022__span'>{props.tituloSpan}</span>
            <div className="o-headboard-informe2022__video">
                <YoutubePlayer youtubeId={props.redireccionBoton} />
            </div>
        </div>
    )
}

export default HeadboardInforme2022
