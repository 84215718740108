import React, {useState, useEffect} from "react"
import { normalizeTwoWords, findTarget, findRel } from "../../../shared/utils/stringUtilities"
import './navAccordion.scss'

const NavAccordion = (props: NavAccordionInterface) => {

	const {
		category,
		index,
		setActiveItem,
		activeItem,
		isAnyActive,
		setIsAnyActive
	} = props;

	const [isActive, toggleActive] = useState(false);

	useEffect(()=>{ 
		index !== activeItem && toggleActive(false)
   }, [activeItem])
   
   const listaMenu = ( subcat:any, k:number, slug:string ) => (
      <li  className= { `gtmMenuTransSubcategoria${normalizeTwoWords(subcat.nombreSubcategoria)}`} key={ `cat${k}` }> 
         <a className= { `gtmMenuTransSubcategoria${normalizeTwoWords(subcat.nombreSubcategoria)}` }
            target={ findTarget(slug) }
            rel={findRel(slug)}
            href={slug}> { subcat.nombreSubcategoria } </a>
      </li>
   )

   const existCategory = category.referenciaSubcategorias && category.referenciaSubcategorias.length > 0;

	return (
			
		<div className='a-nav-accord'>	
			{ 
				category.referenciaSubcategorias ? (
					<button className={ `a-nav-accord__btn ${isActive?'a-nav-accord__btn--active':''} ${!isAnyActive && index!==activeItem?'a-nav-accord__btn--locked':''} 
						gtmMenuTransCategoria${normalizeTwoWords(category.nombreCategoria)}` }
						onClick={ () => {
							setActiveItem(index)
							toggleActive(!isActive)
							setIsAnyActive(isActive)
						}}
						aria-label={category.nombreCategoria}
					>
						{category.nombreCategoria}
						<span className={ `a-nav-accord__btn-icon gtmMenuTransCategoria${normalizeTwoWords(category.nombreCategoria)} `}></span> 
					</button>
				):(
					<a href={`/${category.slug}`} 
						className={ `a-nav-accord__link ${!isAnyActive && index!==activeItem?'a-nav-accord__link--locked':''} 
							gtmMenuTransCategoria${normalizeTwoWords(category.nombreCategoria)}` }> 
						{category.nombreCategoria} 
					</a>
				)
			}
			<ul className={ 
				`a-nav-accord__sub ${isActive && index === activeItem? 'a-nav-accord__sub--active':''}`
				}>
				<li> Encontrarás </li>
            {existCategory &&
               listaMenu(category.referenciaSubcategorias[0], -1, category.referenciaSubcategorias[0].link || `/${category.referenciaSubcategorias[0].slug}`)
            }
            { existCategory && category.referenciaSubcategorias[0].referenciaSubcategorias && 
               category.referenciaSubcategorias[0].referenciaSubcategorias.map((subcat, k) => {
                  return subcat.verEnMenu?listaMenu(subcat,k,subcat.link||`/${category.referenciaSubcategorias[0].slug}${subcat.slug}`):null;
               })
            }
			</ul>
		</div>
	)
}

interface NavAccordionInterface {
	category: any,
	index: number,
	setActiveItem: Function,
	activeItem: number,
	isAnyActive: boolean,
	setIsAnyActive: Function
}

export default NavAccordion
